<template>
    <!-- eslint-disable vue/no-v-model-argument -->
    <main class="grid p-fluid container" :aria-label="$t('accesibility.media.deferred')" style="margin-top: 0rem !important">
        <!-- LOADER -->
        <loading v-model:active="loader.isLoading" :can-cancel="false" :is-full-page="true" color="#007680" :width="loader.loaderSize" :height="loader.loaderSize" :opacity="loader.opacity" />

        <div id="full" class="col-12 xl:col-9 lg:col-10 md:col-11 card m-auto p-1" :aria-label="$t('accesibility.media.player')" style="background-color: black !important">
            <!-- DISPLAY CONTROLS -->
            <div id="display-buttons" class="p-buttonset p-1" :aria-label="$t('accesibility.controls.buttons')">
                <Button id="buttonP1" class="btn-lg py-3 p-button-danger" icon="pi pi-camera" style="font-size: 1.2rem" aria-pressed="true" :aria-label="$t('accesibility.media.player1')" @click="buttonP1">
                    <b>{{ $t("Media.button1") }}</b>
                </Button>
                <Button id="buttonP2" class="btn-lg py-3 p-button-bemyvega" icon="pi pi-video" style="font-size: 1.2rem" aria-pressed="false" :aria-label="$t('accesibility.media.player2')" @click="buttonP2">
                    <b>{{ $t("Media.button2") }}</b>
                </Button>
                <Button id="buttonP3" class="btn-lg py-3 p-button-bemyvega" icon="pi pi-image" style="font-size: 1.2rem" aria-pressed="false" :aria-label="$t('accesibility.media.player3')" @click="buttonP3">
                    <b>{{ $t("Media.button3") }}</b>
                </Button>
            </div>

            <!-- PLAYERS -->
            <div id="wrapper1" class="player-wrapper" :aria-label="$t('accesibility.media.player1')">
                <div id="playerP1div" ref="playerP1"></div>
            </div>
            <div id="wrapper2" class="player-wrapper" style="display: none !important" :aria-label="$t('accesibility.media.player2')">
                <div id="playerP2div" ref="playerP2"></div>
            </div>
            <div id="wrapper3" class="player-wrapper" style="display: none !important" :aria-label="$t('accesibility.media.player3')">
                <div id="playerP3div" ref="playerP3"></div>
            </div>

            <!-- PLAYERS CONTROLS -->
            <div id="controls" style="width: 100%; text-align: center" :aria-label="$t('accesibility.controls.controls')">
                <Slider id="slider1" v-model="sliderLevelDuration" role="slider" :max="duration" style="width: 100%; display: block; float: left; margin-bottom: 0.5rem" aria-valuemin="0" :aria-valuemax="duration" :aria-valuenow="sliderLevelDuration" :aria-label="$t('accesibility.controls.time')" @change="sliderDuration(sliderLevelDuration)" />
                <p id="current" style="color: white; float: left" :aria-label="$t('accesibility.controls.timeIN')">{{ change_totalTime(sliderLevelDuration) }}</p>
                <p id="duration" style="color: white; float: right" :aria-label="$t('accesibility.controls.timeOUT')">{{ change_totalTime(duration) }}</p>
                <Button id="play" class="p-button-player" :aria-label="$t('accesibility.controls.play')" style="float: left; display: inline" @click="play()"> <i class="pi pi-play" style="scale: 2; margin: auto"></i></Button>
                <Button id="pause" class="p-button-player" :aria-label="$t('accesibility.controls.pause')" style="display: none; float: left" @click="play()"> <i class="pi pi-pause" style="scale: 2; margin: auto"></i></Button>
                <Button id="sound" class="p-button-player" :aria-label="$t('accesibility.controls.sound')" style="display: none; float: left" @click="sound_mute()"> <i class="pi pi-volume-up" style="scale: 2; margin: auto"></i></Button>
                <Button id="mute" class="p-button-player" :aria-label="$t('accesibility.controls.mute')" style="float: left" @click="sound_mute()"> <i class="pi pi-volume-off" style="scale: 2; margin: auto"></i></Button>
                <Slider id="slider" v-model="sliderLevelSound" :aria-label="$t('accesibility.controls.sonido')" role="slider" aria-valuemin="0" aria-valuemax="100" :aria-valuenow="sliderLevelSound" :min="0" :max="100" style="width: 5rem; display: inline-block; float: left; margin-top: 1.2rem; padding: 0.2rem" @change="sliderSound(sliderLevelSound)" />
                <Button id="left" class="p-button-player" :aria-label="$t('accesibility.controls.left')" style="float: center" aria-pressed="false" @click="left"> <i class="pi pi-arrow-left" style="scale: 2; margin: auto"></i></Button>
                <Button id="right" class="p-button-player" :aria-label="$t('accesibility.controls.right')" style="float: center" aria-pressed="false" @click="right"><i class="pi pi-arrow-right" style="scale: 2; margin: auto"></i></Button>
                <Button id="maximize" class="p-button-player" :aria-label="$t('accesibility.controls.maximize')" style="float: right" @click="full()"> <i class="pi pi-window-maximize" style="scale: 2; margin: auto"></i></Button>
                <Button id="minimize" class="p-button-player" :aria-label="$t('accesibility.controls.minimize')" style="float: right; display: none" @click="full()"> <i class="pi pi-window-minimize" style="scale: 2; margin: auto"></i></Button>
            </div>
        </div>

        <!-- ROOM NAME - RECORDING NAME -->
        <div class="col-12 md:col-12 flex justify-content-evenly flex-wrap" style="margin-top: 0.5rem; margin-bottom: 0.5rem">
            <p id="roomName" style="font-size: 1.4rem; font-weight: bold">{{ roomName }} : {{ recordingName }}</p>
        </div>
    </main>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import OvenPlayer from "ovenplayer";
// import { env_config } from "../../config.js";
import { RepositoryFactory } from "../repositories/RepositoryFactory";
import { mapState } from "vuex";
import { getUserInfo } from "../utils/auth.js";

const RoomsRepository = RepositoryFactory.get("rooms");
const RecordingsRepository = RepositoryFactory.get("recordings");

export default {
    name: "OvenPlayerDiferido",
    type: Object,
    required: true,
    default: () => {},
    components: {
        Loading
    },
    data() {
        return {
            userMode: null,
            roomId: null,
            roomName: null,
            recordingName: null,
            playerP1: null,
            playerP2: null,
            playerP3: null,
            sliderLevelSound: 0,
            soundLevel: 100,
            sliderLevelDuration: null,
            duration: null,
            fixed: "",
            ptz: "",
            desktop: ""
        };
    },
    computed: {
        ...mapState(["loader"])
    },

    // ------------------------------------- VUE LIFECYCLE ----------------------------------------------------------------
    created() {
        document.title = this.$t("accesibility.title.deferred");
        let { user_mode } = getUserInfo();
        this.userMode = user_mode;
        this.roomId = this.$route.params.id;
        this.recordingName = this.$route.params.recording;
        this.getRoom();
        setInterval(this.time, 1000);
        document.getElementById("app").firstElementChild.classList.add("layout-static-sidebar-inactive");
    },
    async mounted() {
        // Omit rendering in server-side environments
        if (!window || process.server) {
            return;
        }
        await this.getUserRecordings(this.recordingName);
        console.log("setting links...");
        this.playerP1 = OvenPlayer.create("playerP1div", {
            autoStart: true,
            expandFullScreenUI: false,
            showBigPlayButton: false,
            disableSeekUI: true,
            mute: false,
            waterMark: {
                text: "Pizarra",
                font: {
                    "font-size": "20px",
                    color: "#fff",
                    "font-weight": "bold"
                },
                position: "top-left"
            },
            sources: [
                {
                    type: "hls",
                    file: this.fixed
                }
            ]
        });
        this.playerP1.showControls(false);
        this.playerP1.on("clicked", () => {
            this.play();
        });
        this.playerP1.on("metaChanged", () => {
            this.duration = this.playerP1.getDuration();
        });

        this.playerP2 = OvenPlayer.create("playerP2div", {
            autoStart: true,
            expandFullScreenUI: false,
            showBigPlayButton: false,
            disableSeekUI: true,
            mute: false,
            waterMark: {
                text: "Profesor",
                font: {
                    "font-size": "20px",
                    color: "#fff",
                    "font-weight": "bold"
                },
                position: "top-left"
            },
            sources: [
                {
                    type: "hls",
                    file: this.ptz
                }
            ]
        });
        this.playerP2.showControls(false);
        this.duration = this.playerP1.getDuration();
        this.playerP2.on("clicked", () => {
            this.play();
        });

        this.playerP3 = OvenPlayer.create("playerP3div", {
            autoStart: true,
            expandFullScreenUI: false,
            showBigPlayButton: false,
            disableSeekUI: true,
            mute: true,
            waterMark: {
                text: "Desktop",
                font: {
                    "font-size": "20px",
                    color: "#fff",
                    "font-weight": "bold"
                },
                position: "top-left"
            },
            sources: [
                {
                    type: "hls",
                    file: this.desktop
                }
            ]
        });
        this.playerP3.showControls(false);
        this.playerP3.on("clicked", () => {
            this.play();
        });

        console.log("all links set!");
    },

    beforeUnmount() {
        if (this.playerP1) {
            this.playerP1.remove();
        }
        if (this.playerP2) {
            this.playerP2.remove();
        }
        if (this.playerP3) {
            this.playerP3.remove();
        }
        document.getElementById("app").firstElementChild.classList.remove("layout-static-sidebar-inactive");
    },

    methods: {
        async getRoom() {
            let response = await RoomsRepository.getRoom(this.roomId);
            this.roomName = response.data.name;
        },
        async getUserRecordings(directory_name) {
            let response = await RecordingsRepository.getRecordings(this.roomId, directory_name);
            this.fixed = response.data.fixed;
            this.ptz = response.data.ptz;
            this.desktop = response.data.desktop;
            console.log("getting links...");
        },
        // ------------------------------------- DISPLAY CONTROLS FUNCTIONS ----------------------------------------------------------------
        buttonP1() {
            document.getElementById("wrapper1").style.display = "block";
            document.getElementById("wrapper2").style.display = "none";
            document.getElementById("wrapper3").style.display = "none";

            document.getElementById("buttonP1").classList.remove("p-button-bemyvega");
            document.getElementById("buttonP1").classList.add("p-button-danger");
            document.getElementById("buttonP2").classList.remove("p-button-danger");
            document.getElementById("buttonP2").classList.add("p-button-bemyvega");
            document.getElementById("buttonP3").classList.remove("p-button-danger");
            document.getElementById("buttonP3").classList.add("p-button-bemyvega");
            //---------- screen readers: aria-pressed
            document.getElementById("buttonP1").setAttribute("aria-pressed", "true");
            document.getElementById("buttonP2").setAttribute("aria-pressed", "false");
            document.getElementById("buttonP3").setAttribute("aria-pressed", "false");
        },
        buttonP2() {
            document.getElementById("wrapper1").style.display = "none";
            document.getElementById("wrapper2").style.display = "block";
            document.getElementById("wrapper3").style.display = "none";

            document.getElementById("buttonP2").classList.remove("p-button-bemyvega");
            document.getElementById("buttonP2").classList.add("p-button-danger");
            document.getElementById("buttonP1").classList.remove("p-button-danger");
            document.getElementById("buttonP1").classList.add("p-button-bemyvega");
            document.getElementById("buttonP3").classList.remove("p-button-danger");
            document.getElementById("buttonP3").classList.add("p-button-bemyvega");
            //---------- screen readers: aria-pressed
            document.getElementById("buttonP1").setAttribute("aria-pressed", "false");
            document.getElementById("buttonP2").setAttribute("aria-pressed", "true");
            document.getElementById("buttonP3").setAttribute("aria-pressed", "false");
        },
        buttonP3() {
            document.getElementById("wrapper1").style.display = "none";
            document.getElementById("wrapper2").style.display = "none";
            document.getElementById("wrapper3").style.display = "block";

            document.getElementById("buttonP3").classList.remove("p-button-bemyvega");
            document.getElementById("buttonP3").classList.add("p-button-danger");
            document.getElementById("buttonP1").classList.remove("p-button-danger");
            document.getElementById("buttonP1").classList.add("p-button-bemyvega");
            document.getElementById("buttonP2").classList.remove("p-button-danger");
            document.getElementById("buttonP2").classList.add("p-button-bemyvega");
            //---------- screen readers: aria-pressed
            document.getElementById("buttonP1").setAttribute("aria-pressed", "false");
            document.getElementById("buttonP2").setAttribute("aria-pressed", "false");
            document.getElementById("buttonP3").setAttribute("aria-pressed", "true");
        },
        right() {
            if (document.getElementById("wrapper1").style.display != "none") {
                this.buttonP2();
            } else if (document.getElementById("wrapper2").style.display != "none") {
                this.buttonP3();
            } else if (document.getElementById("wrapper3").style.display != "none") {
                this.buttonP1();
            }
        },
        left() {
            if (document.getElementById("wrapper1").style.display != "none") {
                this.buttonP3();
            } else if (document.getElementById("wrapper2").style.display != "none") {
                this.buttonP1();
            } else if (document.getElementById("wrapper3").style.display != "none") {
                this.buttonP2();
            }
        },
        // ------------------- PLAYERS CONTROLS FUNCTIONS -----------------------------------------------------------------------------------------
        play() {
            if (document.getElementById("play").style.display == "inline") {
                this.playerP2.seek(this.playerP1.getPosition());
                this.playerP3.seek(this.playerP1.getPosition());
                this.playerP1.play();
                this.playerP2.play();
                this.playerP3.play();

                document.getElementById("play").style.display = "none";
                document.getElementById("pause").style.display = "inline";
            } else {
                this.playerP1.pause();
                this.playerP2.pause();
                this.playerP3.pause();
                document.getElementById("play").style.display = "inline";
                document.getElementById("pause").style.display = "none";
            }
        },
        // ------------------------------------- POSITION ----------------------------------------------------------------
        sliderDuration(position) {
            this.sliderLevelDuration = this.playerP1.getPosition();
            this.playerP1.seek(position);
            this.playerP2.seek(position);
            this.playerP3.seek(position);
        },
        time() {
            this.sliderLevelDuration = this.playerP1.getPosition();
            if (this.playerP1.getState() == "playing") {
                this.sliderLevelDuration += 1;
            }
            // if (this.player.getPosition() == this.player.getDuration()) {
            //     this.slider1(0);
            //     document.getElementById("play").style.display = "inline";
            //     document.getElementById("pause").style.display = "none";
            // }
        },
        change_totalTime(tseg) {
            var m = Math.floor(tseg / 60);
            var s = (tseg % 60).toFixed();
            if (s == 60) {
                m++;
                s = 0;
            }
            if (s < 10) {
                s = "0" + s;
            }
            if (m < 10) {
                m = "0" + m;
            }
            return String(m) + ":" + String(s);
        },
        // ------------------------------------- SOUND: playerP3 ----------------------------------------------------
        sound_mute() {
            if (this.playerP3.getMute() == false) {
                this.playerP3.setMute(true);
                this.soundLevel = this.sliderLevelSound;
                this.sliderLevelSound = 0;
                document.getElementById("sound").style.display = "none";
                document.getElementById("mute").style.display = "inline";
            } else {
                this.playerP3.setMute(false);
                this.playerP3.setVolume(this.soundLevel);
                document.getElementById("sound").style.display = "inline";
                document.getElementById("mute").style.display = "none";
                this.sliderLevelSound = this.soundLevel;
            }
        },
        sliderSound(sound) {
            this.playerP3.setMute(false);
            this.playerP3.setVolume(sound);
            document.getElementById("sound").style.display = "inline";
            document.getElementById("mute").style.display = "none";
            if (sound == 0) {
                this.playerP3.setMute(true);
                document.getElementById("sound").style.display = "none";
                document.getElementById("mute").style.display = "inline";
            }
        },
        // ------------------------------------- FULL SCREEN --------------------------------------------------------
        full() {
            var elem = document.getElementById("full");
            this.check_filters();
            if (/iphone|ipod/.test(navigator.userAgent.toLowerCase()) == false) {
                if (document.fullscreenElement == elem) {
                    this.closeFullscreen();
                    document.getElementById("minimize").style.display = "none";
                    document.getElementById("maximize").style.display = "inline";
                    document.getElementById("controls").style.display = "block";
                } else {
                    document.getElementById("controls").style.display = "none";
                    this.openFullscreen();
                    document.getElementById("minimize").style.display = "inline";
                    document.getElementById("maximize").style.display = "none";
                }
            }
        },
        check_filters() {
            if (document.documentElement.style.filter == "grayscale(100%)") {
                document.getElementById("full").style.filter = "grayscale(100%)";
            } else if (document.documentElement.style.filter == " invert(100%)") {
                document.getElementById("full").style.filter = "invert(100%)";
            } else if (document.documentElement.style.filter == "brightness(70%) contrast(250%) saturate(0%) invert(100%)" || document.documentElement.style.filter == "brightness(70%) contrast(200%) saturate(0%) invert(100%)") {
                document.getElementById("full").style.filter = "brightness(70%) contrast(250%) saturate(0%) invert(100%)";
            } else if (document.documentElement.style.filter == "brightness(70%) contrast(250%) saturate(0%)" || document.documentElement.style.filter == "brightness(70%) contrast(200%) saturate(0%)") {
                document.getElementById("full").style.filter = "brightness(70%) contrast(250%) saturate(0%)";
            }
        },
        openFullscreen() {
            var elem = document.getElementById("full");
            elem.addEventListener("mousemove", this.handleMouse, true);
            if (navigator.userAgent.indexOf("Safari") == 0) {
                document.getElementById("controls").classList.add("controlstyle1");
            } else {
                document.getElementById("controls").classList.add("controlstyle");
            }
            if (document.addEventListener) {
                document.addEventListener("fullscreenchange", this.exitHandler, false);
                document.addEventListener("mozfullscreenchange", this.exitHandler, false);
                document.addEventListener("MSFullscreenChange", this.exitHandler, false);
                document.addEventListener("webkitfullscreenchange", this.exitHandler, false);
            }
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
                elem.style.backgroundColor = "black";
            } else if (elem.webkitRequestFullscreen) {
                /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                /* IE11 */
                elem.msRequestFullscreen();
            }
            screen.orientation.lock("landscape");
            elem.style.margin = "auto";
            document.getElementById("wrapper1").classList.add("full-height");
            document.getElementById("wrapper2").classList.add("full-height");
            document.getElementById("wrapper3").classList.add("full-height");

            document.getElementById("controls").classList.add("controlstyle");
            document.getElementById("display-buttons").style.display = "none";

            document.getElementById("duration").style.marginRight = "1rem";
        },
        closeFullscreen() {
            document.getElementById("full").style.filter = "none";
            var elem = document.getElementById("full");
            elem.removeEventListener("mousemove", this.handleMouse, true);
            if (navigator.userAgent.indexOf("Safari") == 0) {
                document.getElementById("controls").classList.remove("controlstyle1");
            } else {
                document.getElementById("controls").classList.remove("controlstyle");
            }
            document.getElementById("display-buttons").style.display = "flex";

            document.getElementById("wrapper1").classList.remove("full-height");
            document.getElementById("wrapper2").classList.remove("full-height");
            document.getElementById("wrapper3").classList.remove("full-height");
            document.getElementById("duration").style.marginRight = "0rem";
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                /* IE11 */
                document.msExitFullscreen();
            }
        },
        exitHandler() {
            clearTimeout;
            if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                var elem = document.getElementById("full");
                elem.removeEventListener("mousemove", this.handleMouse, true);
                document.getElementById("full").style.filter = "none";
                if (navigator.userAgent.indexOf("Safari") == 0) {
                    document.getElementById("controls").classList.remove("controlstyle1");
                } else {
                    document.getElementById("controls").classList.remove("controlstyle");
                }
                document.getElementById("controls").style.display = "block";
                document.getElementById("display-buttons").style.display = "flex";
                document.getElementById("wrapper1").classList.remove("full-height");
                document.getElementById("wrapper2").classList.remove("full-height");
                document.getElementById("wrapper3").classList.remove("full-height");

                document.getElementById("minimize").style.display = "none";
                document.getElementById("maximize").style.display = "inline";
            }
        },
        handleMouse() {
            var elem = document.getElementById("full");
            document.getElementById("controls").style.display = "block";
            setTimeout(function () {
                if (document.fullscreenElement == elem) {
                    document.getElementById("controls").style.display = "none ";
                }
            }, 5000);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/demo/badges.scss";
@import "../assets/demo/items.scss";

.player-wrapper {
    margin: 0 auto;
    width: auto;
}

.full {
    position: absolute;
}

.controlstyle {
    position: absolute;
    bottom: 0rem;
    width: 100%;
    z-index: 100;
}
.controlstyle1 {
    width: 100%;
}
.timestyle {
    position: absolute;
    bottom: 2.5rem;
    width: 90%;
    z-index: 100;
    right: 0rem;
}
.full-height {
    height: 100vh;
    margin: auto !important;
}

@media only screen and (max-width: 600px) {
    #display-buttons {
        padding: 0rem !important;
    }
    #buttonP1 {
        font-size: 0.9rem !important;
    }
    #buttonP2 {
        font-size: 0.9rem !important;
    }
    #buttonP3 {
        font-size: 0.9rem !important;
    }

    #play {
        margin: 0rem !important;
    }
    #pause {
        margin: 0rem !important;
    }
    #sound {
        margin: 0rem !important;
    }
    #mute {
        margin: 0rem !important;
    }
    #maximize {
        margin: 0rem !important;
    }
    #minimize {
        margin: 0rem !important;
    }
    #chat {
        margin: 0rem !important;
    }
    #record {
        margin: 0rem !important;
    }

    #norecord {
        margin: 0rem !important;
    }
    #slider {
        margin-top: 1.2rem !important;
        scale: 1;
        padding: 0rem !important;
    }
}
@media only screen and (max-width: 990px) {
    .p-button-player {
        width: 10% !important;
    }
}

@media only screen and (max-width: 450px) {
    .pi {
        scale: 1 !important;
    }
    #slider {
        scale: 0.7;
        width: 3rem;
        padding: 0rem !important;
        height: 0.2rem;
        margin-top: 1.4rem !important;
    }
    #current {
        font-size: smaller !important;
    }
    #duration {
        font-size: smaller !important;
    }
    #roomName {
        font-size: 1rem !important;
    }
}
</style>
